// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("/opt/build/repo/src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-automatic-js": () => import("/opt/build/repo/src/pages/automatic.js" /* webpackChunkName: "component---src-pages-automatic-js" */),
  "component---src-pages-contactus-js": () => import("/opt/build/repo/src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-customers-js": () => import("/opt/build/repo/src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-diesel-js": () => import("/opt/build/repo/src/pages/diesel.js" /* webpackChunkName: "component---src-pages-diesel-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-householdbins-js": () => import("/opt/build/repo/src/pages/householdbins.js" /* webpackChunkName: "component---src-pages-householdbins-js" */),
  "component---src-pages-householdincinerator-js": () => import("/opt/build/repo/src/pages/householdincinerator.js" /* webpackChunkName: "component---src-pages-householdincinerator-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manual-js": () => import("/opt/build/repo/src/pages/manual.js" /* webpackChunkName: "component---src-pages-manual-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-nofuel-js": () => import("/opt/build/repo/src/pages/nofuel.js" /* webpackChunkName: "component---src-pages-nofuel-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-selfwateringpots-js": () => import("/opt/build/repo/src/pages/selfwateringpots.js" /* webpackChunkName: "component---src-pages-selfwateringpots-js" */)
}

